<template>
    <div>
        <headTitle :isMain="2"/>
        <div class="news-box">
            <img class="news-img" src="../../assets/image/mobile/05-3黄金24小时.png"/>
            <div class="title">惊心动魄，可口可乐SCMC的“黄金24小时”</div>
            <div class="date">2022/10/26</div>
            <div class="line"></div>
            <div class="content">随着疫情的常态化发展，近年来，越来越多企业选择将IT架构，重要应用、关键数据等核心业务迁移至云端，由于数据对企业发展有着至关重要的作用，
                    因此在选择云平台时，会优先考虑云平台的安全性，提高业务运维的灵活性和弹性。<br/><br/>

                今年4月以来，上海疫情牵动人心，企业正常运行受到挑战。一旦出现突发问题，而运维人员和关键组件无法第一时间到达到场，将会给企业业务带来极大风险。<br/><br/>

                “黄金24小时”，快速响应，紧急处置<br/>
                可口可乐SCMC作为领军企业，其全国工厂的原料仓储更需要精细化管理。我司构建的一套智能仓储系统（WIS）与可口可乐SCMC业务完全融合在一起，管理着可口可乐SCMC全国14
                家工厂系统，肩负重要使命的WIS需要持续保持正常运转，才不会影响企业的仓储和正常生产。可口可乐SCMC的IT团队对系统的运行一直保持着严格的管理，并实时监控。4月20日
                下午，突发的 “警报声”打破了原本的平静，在现实中上演了一场“黄金24小时”的大片：<br/><br/>

                0~1小时<br/>
                系统监控报警，全国工厂用户陆续上报系统运行缓慢，IT团队快速启动应急预案，排查故障，确定了非软件原因造成。经过深层次定位，发现是私有云服务器发生故障。由于上海全
                域静默，服务器问题无法及时解决，系统使用无法得到保障。<br/><br/>

                1~6小时<br/>
                可口可乐SCMC和我司组成的专家团队快速出击，技术团队综合评估和对客观条件的协调，迅速确定应对方案——整体应用系统迁移上云。经过对几大云商的技术优势和合作经验对比，
                技术团队决定将WIS系统以及历史数据整体迁移至亚马逊云科技的云环境中，力保最短时间内恢复系统！<br/><br/>

                6~20小时<br/>
                争分夺秒的攻坚战开始了，WIS应用环境涉及到虚拟机/缓存/队列/数据库/网络等多个集群服务，涉及到大量的数据，技术团队“分头行动”快速整合备份数据库的相关文件，准备上
                传云端，在迁移过程中，借助Amazon EC2安全灵活的基础设施和丰富的迁移的工具，快速完成本地数据迁移；基于Amazon Aurora云数据库无需预置基础架构等灵活的特点，高效完
                成了关系数据库的部署；整个过程中还充分发挥了Amazon ElastiCache内存数据缓存服务的亚毫秒级响应，和Amazon Managed Streaming for Apache Kafka (MSK)完全托管、
                可用性高且安全的Apache Kafka服务，让应用环境重建能在几个小时内完成，给可口可乐SCMC留出了更充裕的时间进行数据核对和系统检查。<br/><br/>

                20~24小时<br/>
                在可口可乐SCMC与我司技术团队的高效协同下，终于在24小时以内， 让WIS 系统恢复了正常运行，把对业务的影响降到了最低。在上海疫情期间，通过此次“惊心动魄的经历”，大
                家更加意识到，拥有灵活可靠的系统运行环境对业务连续的重要性。<br/><br/>

                可口可乐SCMC的IT总监李磊（Rick）说：“在最关键的时刻，需要快速完成资源整合，才能力保短时间内完成系统修复，以更好的保证系统的正常运行。”<br/><br/>

                与强者同行，让企业从容不迫<br/>
                企业想要日行千里，需有可靠的IT基石以及应对突发状况时的从容。就像我们想要出门旅行，光选择合适的交通工具是远远不够的。如果路途遥远，途中没有服务站，汽车爆胎又无
                法更换的情况下，“诗和远方”就只能是梦想了。<br/><br/>

                面对日益复杂的线下环境，企业想要稳步快速发展，更需要亚马逊云科技的加持，构建更安全可靠的云环境，保障系统的弹性和业务的延续性。未来，我司将持续深化与可口可乐SC
                MC的合作，更好地构建云端运行环境，为企业业务发展续航。<br/><br/>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
            box4List:[
                {
                    index: 1,
                    title:'比孚科技和亚马逊云科技联合主办SAP上云探索及经验分享交流会',
                    date:'2022/11/30',
                    content:'2022年11月30日，比孚科技和亚马逊云科技将在上海地标建筑马勒别墅饭店，联合主办SAP上云探索及经验分享交流会。随着数字经济发展日新月异，企业转型面临多重挑战，云转型无疑成为未来发展的趋势，加上新冠疫情的影响，加速了企业上云的决策，是推进企业数字化转型的关键步伐……',
                    url:require("../../assets/image/index/5-0SAP乘云而上.png"),
                    route:'/mobile-news-detail3'
                },
                {
                    index: 2,
                    title:'第三个3年，比孚再次通过ISO9001认证',
                    date:'2022/11/04',
                    content:'2022年11月3日，上海比孚信息科技有限公司再一次顺利通过了ISO9001的认证。 ISO9001证书有效期为3年，从2014年开始，这已经是我们第三个3年了。 在此认证审核的过程中，我们自上而下，从公司，部门到项目，展示了各层级的整体目标及相应的质量标准，实施方案和成果。',
                    url:require("../../assets/image/index/01-1第三个3年.png"),
                    route:'/mobile-news-detail2'
                },
                {
                    index: 3,
                    title:'惊心动魄，可口可乐SCMC的“黄金24小时”',
                    date:'2022/10/26',
                    content:'随着疫情的常态化发展，近年来，越来越多企业选择将IT架构，重要应用、关键数据等核心业务迁移至云端，由于数据对企业发展有着至关重要的作用，因此在选择云平台时，会优先考虑云平台的安全性，提高业务运维的灵活性和弹性。一旦出现突发问题，而运维人员和关键组件无法第一时间到达到场，将会给企业业务带来极大风险。',
                    url:require("../../assets/image/index/05-0可乐24小时.png"),
                    route:'/mobile-news-detail1'
                }
            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.news-head-line{
    width: 190rem;
    border: 1px solid #F3FAF6;
    margin-top: 11rem;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
}
.news-img{
    width: 100%;
    height: 30rem;
    margin-top: 4rem;
}
.news-img2{
    width: 65.4rem;
    height: auto;
    margin-top: 4rem;
}
.news-box{
    padding-top: 8rem;
    width: 66.5rem;
    margin: auto;
    margin-top: 3rem; 
    .title{
        height: 2.6rem;
        font-size: 2.2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.9rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .date{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.4rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .line{
        width: 100%;
        height: 1px;
        border: 1px solid #EDEDED;
        margin-top: 1rem;
    }
    .content{
        width: 100%;
        // height: 100rem;
        font-size: 2.2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 4rem;
        margin-top: 2.4rem;
        text-align: justify;
    }
}
</style>